export default class GlobalEventBus {
  addGlobalEventListener: any;
  removeGlobalEventListener: any;
  dispatchGlobalEvent: any;

  constructor() {
    this.addGlobalEventListener = (event, callback) => {
      addEventListener(event, callback);
      console.log(`added global event listener for ${event} event`);
    };

    this.removeGlobalEventListener = (event, callback) => {
      removeEventListener(event, callback);
      console.log(`removed global event listener for ${event} event`);
    };

    this.dispatchGlobalEvent = (event, detail = {}) => {
      dispatchEvent(new CustomEvent(event, { detail }));
      console.log(`dispatched global event ${event}`);
    };
  }
}
