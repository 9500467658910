import {ParcelConfig} from 'single-spa';
import {ApplVer} from '../classes/root-config.classes';
import GlobalEventBus from './GlobalEventBus';
import GlobalEventStore from './GlobalEventStore';

/**
 * Returns Parcel config if Application or Parcel in context has access and dependencies setup
 * @param appName
 */
export function getConfig(appName: string, verId?: string): Promise<ParcelConfig | null> {
    let appUrl;
    if (verId) {
      appUrl = this.availableParcels.find(a => a.appl_nm === appName && a.appl_ver_id === verId)?.appl_endpt_url
    } else {
      appUrl = this.availableParcels.find(a => a.appl_nm === appName)?.appl_endpt_url
    }
    
    if (appUrl) {
      return System.import(appUrl);
    }
    return Promise.reject<null>(`No access for ${appName}. Validate your parcel is configured correctly!`)
}

/**
 * returns CustomProps for Parcels with nested Parcels and other standard CustomProps injected by root-spa-ui
 * @param appName
 */
export function getParcelProps(appName: string): Record<string, any> {
    const globalEventBus = new GlobalEventBus();
    const globalEventStore = new GlobalEventStore();
    let availableParcels: ApplVer[] = this.availableParcels?.find(a => a.appl_nm === appName)?.appl_ver_chlds?.map(i => i?.chld_appl) ?? [];
    const parcelHelpers = new ParcelHelpers(this.applNm, availableParcels);
    return {globalEventBus, globalEventStore, parcelHelpers};
}

/**
 * Check if Application or Parcel in context has access
 * @param appName
 */
export function hasAccess(appName: string): boolean {
    return !!this.availableParcels.find(a => a.appl_nm === appName);
}

/**
 * Returns objects needed by `ParcelComponent` maintained by single-spa for Angular implementations
 */
export default class ParcelHelpers {
  availableParcels: ApplVer[];
  applNm: string;
  getConfig: (appName: string) => Promise<ParcelConfig | null>;
  getParcelProps: (appName: string) => Record<string, any>;
  hasAccess: (appName: string) => boolean;

  constructor(applNm: string, availableParcels: ApplVer[]) {
    this.availableParcels = availableParcels || [];
    this.applNm = applNm;
    this.getConfig = getConfig
    this.getParcelProps = getParcelProps
    this.hasAccess = hasAccess
  }
}
