export default class GlobalEventStore {
  store: any;
  add: any;
  get: any;
  remove: any;

  constructor() {
    this.store = {};

    this.add = (key, value) => {
      this.store[key] = value;
    };

    this.get = (key) => {
      return this.store[key];
    };

    this.remove = (key) => {
      this.store[key] = undefined;
    };
  }
}
