import { applSclRefId } from "../enums/root-config.enums";

export class AppDataByCliRole {
  cli_func_role_appl?: CliFuncRoleAppl[];
}

export class CliFuncRoleAppl {
  appl_ver: ApplVer;
}

export class ApplVer {
  appl_nm: string;
  appl_ver_id: string;
  appl_endpt_url: string;
  bas_mnu_url: string;
  appl?: Appl;
  appl_ver_chlds?: ApplVerChld[];
}

export class ApplVerChld {
  appl_nm?: string;
  appl_ver_id?: string;
  chld_appl_nm?: string;
  chld_appl_ver_id?: string;
  chld_appl: ApplVer;
}

export class Appl {
  appl_nm?: string;
  appl_widgets?: ApplWidget[];
  appl_scl_ref_id?: applSclRefId;
}

export class ApplWidget {
  appl_nm?: string;
  widget_nm?: string;
  widget_desc?: WidgetDesc;
  ui_sect_nm?: string;
}

export class WidgetDesc {
  route: string;
  appl_endpt_url: string;
}

export class HasuraResponse<T> {
  data?: T;
  errors?: [
    {
      extensions: {
        path?: string;
        code?: string;
      };
      message?: string;
    }
  ];
}

/**
 * Store and retrieve parcel information
 */
export class ParcelMap {
  private parcelMap: Map<string, ApplVer[]>;
  public get: any;
  public push: any;

  constructor() {
    this.parcelMap = new Map();
    this.get = (parentAppName: string) => this.parcelMap.get(parentAppName);
    this.push = (parentAppName: string, childAppName: string, parcel: ApplVer) => !!this.parcelMap.get(parentAppName) ? this.parcelMap.get(parentAppName).push(parcel) : this.parcelMap.set(parentAppName, [parcel]);
  }

}
